var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"pageTable"}},[(_vm.rol != 'root')?_c('v-container',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"80vh","width":"80vw"}},[_c('v-card',{staticStyle:{"border-radius":"20px"},attrs:{"max-width":"500"}},[_c('v-card-title',{staticClass:"justify-center card_titulo"},[_c('div',{staticClass:"headerModal"},[_c('img',{staticClass:"imgModal",attrs:{"src":'/static/icon/peligro.svg'}})])]),_c('v-card-text',{staticClass:"titleModal text-center"},[_vm._v(" Esta vista no esta disponible para este rol de usuario. ")]),_c('v-card-actions',{staticClass:"d-flex align-center justify-center card_accion"},[_c('v-btn',{staticClass:"btnGuardar",on:{"click":function($event){return _vm.redireccionar()}}},[_vm._v(" Ok ")])],1)],1)],1):_c('v-container',{attrs:{"xl":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('data-table',{ref:"tabla",attrs:{"tableName":'Permisos - Categorias',"url":_vm.url,"columns":_vm.columns,"filters":_vm.filters,"perPage":[10, 25, 50, 100],"showAdvancedFilters":true},on:{"setFilters":_vm.setFilters,"loadModalData":_vm.loadModalData},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return _c('tbody',{},_vm._l((data),function(item){return _c('tr',{key:item.id},[_c('td',[_c('div',{staticClass:"tblPrincipal"},[_vm._v(_vm._s(item.clave))])]),_c('td',[_c('div',{staticClass:"tblPrincipal"},[_vm._v(_vm._s(item.name))])]),_c('td',[_c('div',{staticClass:"tblPrincipal"},[_vm._v(_vm._s(item.display_name))])]),_c('td',[_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"slot":"activator","icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModal('update', item)}},slot:"activator"},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"slot":"activator","depressed":"","icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.eliminar(item)}},slot:"activator"},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotonesDelete"},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])])}),0)}}])},[_c('template',{slot:"actionButtonsLeft"},[_c('div',{staticClass:"botonAgregar"},[_c('v-btn',{staticClass:"btnAdd",attrs:{"rounded":"","color":"#004BAF","right":""},on:{"click":function($event){return _vm.abrirModal('add')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("add")]),_vm._v("Agregar "),_c('div',{staticClass:"mr-4"})],1)],1)]),_c('template',{slot:"filters"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Nombre de la categoria"},model:{value:(_vm.filterNombre),callback:function ($$v) {_vm.filterNombre=$$v},expression:"filterNombre"}})],1)],1)],2)],1)],1),[_c('v-dialog',{staticClass:"borde-card",attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonModal",attrs:{"id":"btnModal"}},on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"borde-card"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{staticClass:"card_titulo padding_izquierdo padding_derecho"},[_c('div',{staticClass:"headerModalStart"},[_c('h2',{staticClass:"titleModal_700_18"},[_vm._v(_vm._s(_vm.tituloModal))])])]),_c('v-card-text',{staticClass:"card_texto"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoadingModal),expression:"isLoadingModal"}],attrs:{"id":"padre"}},[_c('div',{attrs:{"id":"loading"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":100,"width":7,"color":"blue"}})],1)]),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingModal),expression:"!isLoadingModal"}],attrs:{"grid-list-md":"","id":"contenedor"}},[_c('v-row',{staticClass:"my-5"},[_c('v-col',{staticClass:"pt-0 pb-0 ma-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Clave","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Clave","error-messages":errors,"readonly":(_vm.accion == 'update' ? true : false)},model:{value:(_vm.categoria.clave),callback:function ($$v) {_vm.$set(_vm.categoria, "clave", $$v)},expression:"categoria.clave"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-0 pb-0 ma-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Nombre","error-messages":errors,"readonly":(_vm.accion == 'update' ? true : false)},model:{value:(_vm.categoria.name),callback:function ($$v) {_vm.$set(_vm.categoria, "name", $$v)},expression:"categoria.name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-0 pb-0 ma-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Nombre a mostrar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Nombre a mostrar","error-messages":errors},model:{value:(_vm.categoria.display_name),callback:function ($$v) {_vm.$set(_vm.categoria, "display_name", $$v)},expression:"categoria.display_name"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end card_accion"},[_c('button',{staticClass:"btnCerrar",attrs:{"disabled":_vm.isSaving},on:{"click":function($event){return _vm.cerrarModal()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"btnGuardar",attrs:{"disabled":invalid,"loading":_vm.isSaving},on:{"click":function($event){return _vm.guardar()}}},[_vm._v(" Guardar ")])],1)]}}])})],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }